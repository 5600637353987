@font-face {
  font-family: 'OpenSans';
  src: url('./OpenSans-Bold.woff2') format('woff2'),
      url('./OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./OpenSans-Italic.woff2') format('woff2'),
      url('./OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./OpenSans-Regular.woff2') format('woff2'),
      url('./OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Bold.woff2') format('woff2'),
      url('Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Medium.woff2') format('woff2'),
      url('Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

